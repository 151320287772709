<template>
  <v-row class="h-full justify-center algin-center d-flex py-4 ma-0">
    <v-col
      cols="12"
      sm="6"
      lg="4"
      style="maxWidth: 600px"
      class="d-flex flex-column align-center justify-center ma-0"
    >
      <v-card class="w-full">
        <v-card-title class="d-flex justify-center">
          <v-img
            alt="Company Logo"
            class="my-2"
            :src="require('@/../public/img/logo.png')"
            max-width="140"
            contain
          />
        </v-card-title>

        <v-card-text v-if="isLoading">
          <div class="d-flex flex-column align-center">
            <v-progress-circular
              indeterminate
              color="primary"
              width="3"
              size="30"
            />
            <p>{{ readablePlatformName }} wird mit onOffice verbunden...</p>
          </div>
        </v-card-text>

        <v-card-text v-if="hasError">
          <v-alert
            class="ma-0"
            type="error"
          >
            <span>
              {{ errorMessage }}
            </span>
          </v-alert>
        </v-card-text>

        <v-card-text v-if="isConnected">
          <v-alert
            class="ma-0"
            type="success"
          >
            <span>
              Geschafft!<br>
              Sie haben <b>onOffice</b> erfolgreich mit {{ readablePlatformName }} verbunden.
            </span>
          </v-alert>
          <p class="mt-2 text-subtitle-1">
            <b>{{ $t('labels.caution') }}:</b> Die Synchronisation ist noch nicht aktiv.
            Konfigurieren und aktivieren Sie die Synchronisation in den <b><a href="/settings/crm">Einstellungen</a></b>.
          </p>
          <v-btn
            color="primary"
            class="w-full"
            to="/settings/crm"
          >
            Jetzt Einstellungen für onOffice anpassen
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import CONNECT_ONOFFICE_USER from './ConnectOnofficeUser.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  mixins: [brandingMixin],
  data () {
    return {
      error: null,
      submitState: 'PENDING'
    }
  },
  computed: {
    hasError () {
      return this.error !== null
    },
    isConnected () {
      return this.error === null && this.submitState === 'FINISHED'
    },
    isLoading () {
      return this.submitState === 'PENDING'
    },
    errorMessage () {
      return {
        unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        invalidToken: 'Der API-Key ist ungültig. Bitte versuchen Sie es in onOffice erneut.'
      }[this.error]
    }
  },
  mounted () {
    const token = this.$route.params.token
    if (token) { this.connectOnOffice(token) }
  },
  methods: {
    async connectOnOffice (token) {
      this.error = null
      this.submitState = 'PENDING'
      try {
        await this.$apollo.mutate({
          mutation: CONNECT_ONOFFICE_USER,
          variables: {
            input: {
              token
            }
          }
        })
        bus.$emit(eventNames.CRM_UPDATED)
        this.$router.replace({ path: '/settings/crm' })
      } catch (err) {
        if (err?.message?.match(/Invalid token/)) {
          this.error = 'invalidToken'
        } else {
          this.error = 'unknown'
        }
      } finally {
        this.submitState = 'FINISHED'
      }
    }
  }
}
</script>
