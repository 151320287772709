<template>
  <v-row class="h-full justify-center algin-center d-flex py-4 ma-0">
    <v-col
      cols="12"
      sm="6"
      lg="4"
      style="maxWidth: 600px"
      class="d-flex flex-column align-center justify-center ma-0"
    >
      <v-card class="w-full">
        <v-card-title class="d-flex justify-center">
          <v-img
            alt="Company Logo"
            class="my-2"
            :src="require('@/../public/img/logo.png')"
            max-width="140"
            contain
          />
        </v-card-title>

        <v-card-text v-if="isLoading">
          <div class="d-flex flex-column align-center">
            <v-progress-circular
              indeterminate
              color="primary"
              width="3"
              size="30"
            />
            <p>Sie werden eingeloggt</p>
          </div>
        </v-card-text>

        <v-card-text v-if="isConnected">
          <div class="d-flex flex-column align-center">
            <p>Sie wurden erfolgreich eingeloggt</p>
          </div>
        </v-card-text>

        <v-card-text v-if="errorMessage">
          <v-alert
            class="ma-0"
            type="error"
          >
            <span>
              {{ errorMessage }}
            </span>
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  mixins: [brandingMixin],
  data () {
    return {
      error: null,
      submitState: 'PENDING'
    }
  },
  computed: {
    isConnected () {
      return this.$auth.isAuthenticated
    },
    isLoading () {
      return this.$auth.loading
    },
    errorMessage () {
      const url = new URL(window.location.href)
      const { apiClaim: extendedClaim, timestamp, customerName, customerWebId, userId, signature, parameterCacheId } = Object.fromEntries(url.searchParams.entries())
      if (![extendedClaim, timestamp, customerName, customerWebId, userId, signature, parameterCacheId].every(Boolean)) {
        return 'Der Aufruf ist ungültig. Bitte versuchen Sie es in onOffice erneut.'
      }
      return {
        userNotFound: 'Kein gültiger Zugang.',
        marketplaceAppNotAllowed: 'Dieser Service ist nicht aktiv.',
        unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        invalidParameter: 'Der Aufruf ist ungültig. Bitte versuchen Sie es in onOffice erneut.'
      }[this.$auth.error]
    }
  },
  created () {
    if (this.$auth.isAuthenticated) {
      this.$router.push('/dashboard')
    } else {
      bus.$on(eventNames.USER_LOGGED_IN, () => {
        this.$router.push('/dashboard')
      })
    }
  }
}
</script>
